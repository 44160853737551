import { selectOrganizationSchema } from '@repo/db/schema'
import { z } from 'zod'

export const CreateOrganizationSchema = z.object({
  intent: z.literal('createOrganization'),
  name: selectOrganizationSchema.shape.name,
  successPath: z.string().optional()
})

export const SwitchOrganizationSchema = z.object({
  intent: z.literal('switchOrganization'),
  orgId: selectOrganizationSchema.shape.id
})
